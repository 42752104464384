<template>
  <div>
		<page-header
			title="All done"
			subtitle=""
		>
			<template v-slot:content>
				Thank you for sharing this information with us. A member of staff will take you through the next steps. 
				The following links might be interesting for you to explore while you wait.
				If you have any questions, you can ask our friendly staff. They're used to all sorts of questions about relationships, sex and sexual health (even the awkward ones!) and will do their best to provide you with helpful support and advice. 
			</template>
		</page-header>
		<page-container>

			<!-- ECC Results-->
			<div v-if="hasECCResult" id="ecc-results">
				<h2 class="mb-4">Chance of pregnancy</h2>
				<div class="row">
					<Results
						:ec="ec"
						:risk="pregnancyRisk"
					/>
				</div>
			</div>

			<!-- Content Mapping Results-->
			<h2 class="mb-4">Useful information and advice</h2>
			<div class="row">
				<!-- ECC Content -->
				<Card v-if="hasECCResult"
					title="Emergency contraception"
					content="Information about the two types of emergency contraception that can be used to prevent pregnancy."
					link="https://www.brook.org.uk/your-life/emergency-contraception/"
					textColor="text-black"
					bgColor="bg-e34598"
				/>
				<Card v-if="hasECCResult"
					title="The IUD"
					content="What the IUD is and how it works as both emergency contraception and ongoing contraception."
					link="https://www.brook.org.uk/your-life/iud-intrauterine-device/"
					textColor="text-black"
					bgColor="bg-f16822"
				/>

				<!-- Age Content -->
				<Card v-if="calculateAge < 19 && calculateAge != ''"
					title="Childline - 24/7 support for under 19s"
					content="Confidential support for anyone under 19. Even if you don't have credit you can still call for free."
					link="https://www.childline.org.uk/get-support/contacting-childline/"
					textColor="text-black"
					bgColor="bg-75bf43"
				/>
				<Card v-else
					title="Samaritans - 24/7 support, whatever you are going through"
					content="Free and confidential emotional support from trained volunteers."
					link="https://www.samaritans.org/how-we-can-help/contact-samaritan/"
					textColor="text-black"
					bgColor="bg-75bf43"
				/>

				<!-- Drugs Content -->
				<Card v-if="includesDrugsOrAlcohol"
					title="Frank - help with drugs and alcohol"
					content="Friendly, confidential advice and honest information about drugs."
					link="https://www.talktofrank.com/contact-frank"
					textColor="text-black"
					bgColor="bg-f7c412"
				/>

				<!-- General Content -->
				<Card
					v-for="(content, i) in results"
					:key="i"
					:title="content.title"
					:content="content.description"
					:link="content.url"
					:textColor="content.text_color"
					:bgColor="content.bg_color"
				/>

			</div>

			<button
				class="btn py-3 btn-primary font-weight-bold border-rounded mt-4 d-block w-100"
				@click="onSubmit"
			>
				<span>Return Home</span>
			</button>

		</page-container>
  </div>
</template>

<script>
// State
import { mapGetters } from 'vuex'

// Mixins
import mixpanelTracking from '@/mixins/mixpanel'

// Content JSON
import contentResults from '@/inc/content/results'

// Form Components
import pageHeader from '@/components/general/page-header'
import pageContainer from '@/components/general/page-container'
import Card from '@/components/general/card'
import Results from '@/components/steps/ecc/results'

export default {
	mixins: [ mixpanelTracking ],
	components: {
		pageHeader,
		pageContainer,
		Card,
		Results
	},
	data() {
		return {
			loading: true,
			results: contentResults,
			mappedResults: [],
			ec: null,
			pregnancyRisk: null,
			dob: null,
			useDrugs: null,
			genitals: null,
			completedEcc: null
		}
	},
	computed: {
		...mapGetters([
			'getEcc',
			'getGenitals',
			'getIntoxicantsUsed',
			'getDateOfBirth',
			'getEC',
			'getPregnancyRisk'
		]),
		hasECCResult() {
			// Check data to see if user has an ECC result
			return !!this.ec || !!this.pregnancyRisk
		},
		includesDrugsOrAlcohol() {
			// Check data to see if user has used drugs
			if (this.useDrugs.includes('Drugs') || this.useDrugs.includes('Alcohol')) {
				return true
			}

			return false
		},
		calculateAge() {
			// Setup dob function
			// Replace in v2 as user will provide age through form.
			let calculateAge = ''
			
			// Get todays date & birthdate in (ms)
			const today = Date.now()
			const birthDate = new Date(this.dob)

			// Calculate difference in time
			const diff = today - birthDate.getTime()

			// Get new date from difference and calculate
			const ageDate = new Date(diff)
			const age = Math.abs(ageDate.getUTCFullYear() - 1970);

			// Set calcualted age
			calculateAge = age

			return calculateAge
		},
	},
	methods: {
		async onSubmit() {
			// Reset all states
			await this.$store.dispatch('resetForm')
			await this.$store.dispatch('resetECC')
			await this.$store.dispatch('resetState')
			// Refresh window and return to home
			await this.$router.push(
				this.$GLOBALS.PATH_WELCOME
			)
		},
		pageData() {
			// Sets page data ready to clear vuex state
			this.completedEcc = this.getEcc
			this.genitals = this.getGenitals
			this.ec = this.getEC
			this.pregnancyRisk = this.getPregnancyRisk
			this.dob = this.getDateOfBirth
			this.useDrugs = this.getIntoxicantsUsed
		},
		checkData() {
			// Checks if ECC data is available but the validation was non-risk
			if (
				this.completedEcc &&
				this.genitals === 'Vagina' &&
				this.ec === '' &&
				this.pregnancyRisk === ''
			) {
				this.ec = 'ineligible'
				this.pregnancyRisk = 'low'
			}
		},
		async clearData() {
			// Clears all data on load
			await this.$store.dispatch('resetForm')
			await this.$store.dispatch('resetECC')
			await this.$store.dispatch('resetState')
		},
		completedSubmission() {
			if (process.env.VUE_APP_ENABLE_MIXPANEL != 'false' && process.env.NODE_ENV === 'production') {
				this.$mixpanel.track('Completed Submission');
			}
		}
	},
	mounted() {
		this.completedSubmission()
	},
	async created() {
		/**
		 * On Load
		 * Set page data locally and check required data is available
		 * Clear all stored data in vuex state
		 */
		await this.pageData()
		await this.checkData()
		await this.clearData()
	}
}
</script>
