<template>
	<div class="col-sm-12 mb-4">
		<!-- Result Section -->
		<div
			class="card mb-4"
			:class="result.color"
		>
			<div class="card-header font-weight-bold risk-title">{{ result.title }}</div>

			<div class="card-body">
				<!-- Chance Messaging -->
				<div v-if="risk != 'incalculable'">
					<p v-if="risk === 'high'" class="card-text">Based on the information you have provided, <strong>the chance of you falling pregnant is {{ risk }}</strong>.</p>
					<p v-if="risk === 'low'" class="card-text">Based on the information you have provided, <strong>the chance of you falling pregnant is {{ risk }}</strong> but it is still possible.</p>

					<p class="card-text font-weight-bold">How is this worked out?</p>
					<p class="card-text">We have worked out the chance of you falling pregnant using the information you have given us about when your last period was, the length of your menstrual cycle and when unprotected sex happened.</p>
				
					<p v-if="risk === 'low'" class="card-text">There are times in your cycle when the chance of falling pregnant is higher, and times when it is lower. Based on what you have told us about when during your cycle unprotected sex happened, the chance of you falling pregnant is low, but it is still possible.</p>
					<p v-if="risk === 'high'" class="card-text">There are times in your cycle when the chance of falling pregnant is higher, and times when it is lower. Based on what you have told us about when during your cycle unprotected sex happened, the chance of you falling pregnant is high.</p>

					<p class="card-text font-weight-bold">What are my options for emergency contraception?</p>
					
					<p class="card-text">The type of emergency contraception that is suitable for you depends on how long ago unprotected sex happened.</p>
				</div>
				<div v-else>
					<p class="card-text">Based on the information you have provided, <strong>there is a chance that you could fall pregnant</strong>. We are unable to calculate if the chance is high or low at this time because we don't have all the information.</p>

					<p class="card-text font-weight-bold">How is this worked out?</p>
					<p class="card-text">As you have had unprotected sex, there is a chance that you could fall pregnant. There are times in your menstrual cycle when the chance of falling pregnant is higher, and times when it is lower. We are unable to calculate if the chance is high or low at this time because we don't have all the information needed.</p>

					<p class="card-text font-weight-bold">What are my options for emergency contraception?</p>
					
					<p class="card-text">The type of emergency contraception that is suitable for you depends on how long ago unprotected sex happened.</p>
				</div>
			</div>
		</div>

		<!-- EC Options Section -->
		<div class="card mb-4 bg-light">
			<button
				id="ecc-section-01"
				role="button"
				aria-controls="sect1"
				:aria-expanded="isOptions ? 'true' : 'false'"
				@click="isOptions = !isOptions"
				class="card-header font-weight-bold d-flex justify-content-between align-items-center risk-title border-0"
			>
				<span>Options for emergency contraception</span>
				<span v-if="isOptions" aria-hidden="true">&minus;</span>
				<span v-else aria-hidden="true">&plus;</span>
			</button>

			<div
				v-show="isOptions"
				class="card-body"
				role="region"
				aria-labelledby="ecc-section-01"
			>
				<!-- Eligible EC -->
				<div v-if="ec === 'eligible'">
					<p class="card-text">If you want to use emergency contraception, your options are:</p>

					<p class="card-text font-weight-bold">IUD</p>

					<p class="card-text">The intrauterine device (IUD) is a small plastic and copper device that is fitted in your uterus (womb) by a specially trained doctor or nurse. It prevents pregnancy by stopping the egg implanting in your womb or being fertilised.</p>

					<p class="card-text font-weight-bold">The IUD is over 99% effective, making it the most effect method of emergency contraception.</p>

					<p class="card-text">It needs to be fitted within five days (120 hours) of unprotected sex or up to five days after the earliest time you could have released an egg (ovulated).</p>

					<p class="card-text">It takes about 15-20 minutes to fit the IUD and can be uncomfortable, however, you can have a local anaesthetic to help with this.</p>

					<p class="card-text font-weight-bold">After having the IUD fitted you can continue to use this is as your usual method of contraception.</p>

					<p class="card-text">If you want to have the IUD removed this will need to be done by a specially trained doctor or nurse after your next period.</p>

					<p class="card-text font-weight-bold">ellaOne (Ulipristal Acetate / UPA)</p>

					<p class="card-text">ellaOne is a type of emergency hormonal contraception that comes as a pill. It works by stopping progesterone (a hormone women produce in their ovaries) from working normally and prevents pregnancy by delaying or preventing ovulation.</p>

					<p class="card-text font-weight-bold">If taken within five days (120 hours) of having unprotected sex, it is almost 98% effective at preventing pregnancy.</p>

					<p class="card-text">ellaOne <strong>does not</strong> protect you from further unprotected sex.</p>

					<p class="card-text font-weight-bold">Levonelle (Levonorgestrel / LNG)</p>

					<p class="card-text">Levonelle is a type of emergency hormonal contraception that comes as a pill. It contains a progestogen hormone called Levonorgestrel and prevents pregnancy by stopping or delaying ovulation (when your ovaries release an egg). Other brands include Levonelle One Step or Upostelle.</p>

					<p class="card-text"><strong>It is most effective when taken within 72 hours of unprotected sex</strong>, but it can be used up to 96 hours (4 days) after. Some services may only offer Levonelle up to 72 hours after unprotected sex.</p>

					<p class="card-text">Levonelle <strong>does not</strong> protect you from further unprotected sex.</p>
				</div>

				<!-- Urgent EC -->
				<div v-else-if="ec === 'urgent'">
					<p class="card-text">If you want to use emergency contraception, your options are:</p>

					<p class="card-text font-weight-bold">IUD</p>

					<p class="card-text">The intrauterine device (IUD) is a small plastic and copper device that is fitted in your uterus (womb) by a specially trained doctor or nurse. It prevents pregnancy by stopping the egg implanting in your womb or being fertilised.</p>

					<p class="card-text font-weight-bold">The IUD is over 99% effective, making it the most effect method of emergency contraception.</p>

					<p class="card-text">It needs to be fitted within five days (120 hours) of unprotected sex or up to five days after the earliest time you could have released an egg (ovulated).</p>

					<p class="card-text">It takes about 15-20 minutes to fit the IUD and can be uncomfortable, however, you can have a local anaesthetic to help with this.</p>

					<p class="card-text font-weight-bold">After having the IUD fitted you can continue to use this is as your usual method of contraception. </p>

					<p class="card-text">If you want to have the IUD removed this will need to be done by a specially trained doctor or nurse after your next period.</p>

					<p class="card-text font-weight-bold">ellaOne (Ulipristal Acetate / UPA)</p>

					<p class="card-text">ellaOne is a type of emergency hormonal contraception that comes as a pill. It works by stopping progesterone (a hormone women produce in their ovaries) from working normally and prevents pregnancy by delaying or preventing ovulation.</p>

					<p class="card-text font-weight-bold">If taken within five days (120 hours) of having unprotected sex, it is almost 98% effective at preventing pregnancy.</p>

					<p class="card-text">ellaOne does not protect you from further unprotected sex.</p>
				</div>

				<!-- IUD EC -->
				<div v-else-if="ec === 'iud' && risk != 'incalculable'">
					<p class="card-text">Based on your answers, emergency hormonal contraception is unlikely to be effective but you may still be able to have an IUD fitted to prevent pregnancy.</p>

					<p class="card-text font-weight-bold">IUD</p>

					<p class="card-text">The intrauterine device (IUD) is a small plastic and copper device that is fitted in your uterus (womb) by a specially trained doctor or nurse. It prevents pregnancy by stopping the egg implanting in your womb or being fertilised.</p>

					<p class="card-text font-weight-bold">The IUD is over 99% effective, making it the most effect method of emergency contraception.</p>

					<p class="card-text">It needs to be fitted within five days (120 hours) of unprotected sex or up to five days after the earliest time you could have released an egg (ovulated).</p>

					<p class="card-text">It takes about 15-20 minutes to fit the IUD and can be uncomfortable, however, you can have a local anaesthetic to help with this.</p>

					<p class="card-text font-weight-bold">After having the IUD fitted you can continue to use this is as your usual method of contraception.</p>

					<p class="card-text">If you want to have the IUD removed this will need to be done by a specially trained doctor or nurse after your next period.</p>
				</div>

				<!-- Ineligible EC -->
				<div v-else>
					<p class="card-text">Based on your answers, emergency contraception is unlikely to be effective.</p>
				</div>
			</div>
		</div>

		<!-- What should I do now section -->
		<div class="card mb-4 bg-light">
			<button
				id="ecc-section-02"
				aria-controls="sect2"
				:aria-expanded="isHidden ? 'true' : 'false'"
				@click="isHidden = !isHidden"
				class="card-header font-weight-bold d-flex justify-content-between align-items-center risk-title border-0"
			>
				<span>Next steps</span>
				<span v-if="isHidden" aria-hidden="true">&minus;</span>
				<span v-else aria-hidden="true">&plus;</span>
			</button>

			<div
				v-show="isHidden"
				class="card-body"
				role="region"
				aria-labelledby="ecc-section-02"
			>
				<div v-if="ec != 'ineligible'">
					<p class="card-text font-weight-bold">What should I do now?</p>

					<p class="card-text">If you want to get emergency contraception, you should contact your local Brook service <strong>as soon as possible</strong> and ask to speak to someone about emergency contraception. Our staff are there to help and will not judge you.</p>

					<p class="card-text">If you can, take a screenshot of this page to show the person that you speak to.</p>
				</div>
				<div v-else>
					<p class="card-text font-weight-bold">What should I do now?</p>

					<p class="card-text">Contact your local Brook service <strong>as soon as possible</strong> for help and support. Our staff will be able advise you on your options and help you with taking a pregnancy test if you want to. They will also be able to help you with making choices about contraception so you are protected from now on.</p>

					<p class="card-text">If you can, take a screenshot of this page to show the person that you speak to.</p>

					<p class="card-text"><strong>Remember:</strong> using this tool only estimates your <strong>chance</strong> of falling pregnant based on the information you have provided. It cannot tell you if are already pregnant or if you definitely will or won't fall pregnant. The only way to know if you are pregnant or not is to take a pregnancy test. Pregnancy tests should be taken 3 weeks after the unprotected sex happened.</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			isHidden: false,
			isOptions: false
		}
	},
	props: {
		title: {
			type: String,
			default: ''
		},
		content: {
			type: String,
			default: ''
		},
		risk: {
			type: String,
			default: ''
		},
		ec: {
			type: String,
			default: ''
		}
	},
	computed: {
		result() {
			// Result empty variables
			let result = {
				title: '',
				color: ''
			}

			// Update result object based on risk outcome
			switch(this.risk) {
				case 'low':
					result.title = 'Low chance of pregnancy'
					result.color = 'bg-low'
					break
				case 'high':
					result.title = 'High chance of pregnancy'
					result.color = 'bg-high'
					break
				case 'incalculable':
					result.title = 'Risk incalculable'
					result.color = 'bg-incalculable'
			}

			return result
		}
	}
}
</script>

<style scoped>
	.bg-low {
		background-color: #c6f6d5;
	}

	.bg-high {
		background-color: #feb2b2;
	}

	.bg-incalculable {
		background-color: #d6bcfa;
	}

	.risk-title {
		font-size: 1.25rem;
	}
</style>