<template>
	<div class="col-sm-4 mb-4">
		<div class="card bg-light h-100">
			<div
				v-if="title"
				class="card-header font-weight-bold"
				:class="[
					textColor ? textColor : null,
					bgColor ? bgColor : null
				]">{{ title }}</div>
			<div class="card-body d-flex flex-column justify-content-between">
				<p v-if="content" class="card-text">{{ content }}</p>
				<a
					v-if="link"
					:href="link"
					target="_blank"
					rel="noopener"
					class="btn align-self-start"
					:class="buttonColor"
				>{{ label }}</a>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			default: ''
		},
		content: {
			type: String,
			default: ''
		},
		link: {
			type: String,
			default: ''
		},
		label: {
			type: String,
			default: 'Read more'
		},
		rank: {
			type: [String, Number],
			default: ''
		},
		buttonColor: {
			type: String,
			default: 'btn-primary'
		},
		textColor: {
			type: String,
			default: ''
		},
		bgColor: {
			type: String,
			default: ''
		}
	},
	computed: {
		backgroundStyle() {
			return `background-color: ${this.bgColor} !important`
		},
		textStyle() {
			return `text-color: ${this.textColor} !important`
		}
	}
}
</script>

<style lang="css" scoped>
	.text-black { color: #000000 !important; }
	.bg-f16822 { background-color: #f16822 !important; }
	.bg-663290 { background-color: #663290 !important; }
	.bg-e51d38 { background-color: #e51d38 !important; }
	.bg-e34598 { background-color: #e34598 !important; }
	.bg-44d0d2 { background-color: #44d0d2 !important; }
	.bg-f7c412 { background-color: #f7c412 !important; }
	.bg-75bf43 { background-color: #75bf43 !important; }
</style>